// Third party
import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";

// Proprietary
import Section from "./Section";
import Container from "./Container";

// Theme
import theme from "../theme";

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  display: inline-block;
  padding: 0 0 0 0.5em;
  margin: 0;
  color: ${theme.mono};
  &::after {
    content: ">";
    padding: 0 0 0 0.5em;
  }
  &:last-child {
    ::after {
      content: none;
    }
  }
`;

const BreadcrumbSection = styled(Section)`
  padding: 30px 0;
`;

const Breadcrumb = props => (
  <BreadcrumbSection inverted>
    <Container>
      <Wrapper itemScope itemType="http://schema.org/BreadcrumbList">
        {props.items.map((item, i) => {
          return (
            <Item
              key={item.id}
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <Link to={item.slug} itemProp="item">
                <span itemProp="name">{item.title}</span>
                <meta itemProp="position" content={item.id} />
              </Link>
            </Item>
          );
        })}
      </Wrapper>
    </Container>
  </BreadcrumbSection>
);

export default Breadcrumb;
