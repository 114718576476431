import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import moment from "moment";
import styled from "styled-components";

import Avatar from "../components/Avatar";

const ContentList = props => {
  return (
    <div className="grid">
      {props.items.map(item => {
        const {
          id,
          title,
          createdAt,
          slug,
          avatar,
          body: {
            childMarkdownRemark: { excerpt },
          },
          author = {},
        } = item.node;
        const { title: authorTitle = null, slug: authorSlug = null } = author;
        const Heading = styled.h2`
          font-size: 1.777rem;
        `;

        return (
          <div key={id}>
            {avatar && (
              <div
                className="grid__col grid__col--1-of-4"
                style={{ textAlign: "center" }}
              >
                <Avatar
                  size="large"
                  style={{ marginTop: "1.45rem" }}
                  src={avatar.fixed.src}
                  alt={title}
                  title={title}
                  loading="lazy"
                />
              </div>
            )}
            <div className="grid__col grid__col--3-of-4">
              {title && (
                <Heading>
                  <Link to={`/${props.parent}/${slug}`}>{title}</Link>
                </Heading>
              )}
              {createdAt && (
                <p>
                  <small>
                    {moment(createdAt).format("MMM Do YYYY")}{" "}
                    {authorSlug && authorTitle && (
                      <>
                        <span>{"door "}</span>
                        <Link to={`/teachers/${authorSlug}`}>
                          {authorTitle}
                        </Link>
                      </>
                    )}
                  </small>
                </p>
              )}
              {excerpt && <p>{excerpt}</p>}
              {slug && (
                <Link to={`/${props.parent}/${slug}`}>Verder lezen</Link>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContentList;

ContentList.propTypes = {
  items: PropTypes.array,
};
