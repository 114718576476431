import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const sizes = {
  small: "63px",
  large: "224px",
};

const Avatar = styled.img`
  width: ${props => (props.size ? sizes[props.size] : "112px")};
  height: ${props => (props.size ? sizes[props.size] : "112px")};
  border-radius: 500px;
`;

export default Avatar;

Avatar.propTypes = {
  alt: PropTypes.string,
  size: PropTypes.string,
  src: PropTypes.string.isRequired,
};
