// Third party
import React from "react";
import styled from "styled-components";

// Theme
import theme from "../theme";

// Assets
import pattern from "../assets/images/pattern.png";

const Element = styled.div`
  background-image: url(${pattern});
  background-repeat: repeat-x;
  background-position: 50% -75px;
  background-color: ${theme.brandSecondary};
  color: ${theme.monoLight};
  padding: ${props => (props.small ? "200px 0 50px" : "300px 0 100px")};
  min-height: ${props => (props.small ? "325px" : "600px")};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.monoLightest};
  }
  @media (max-width: 480px) {
    padding-top: 175px;
    min-height: ${props => (props.small ? "220px" : "375px")};
  }
`;

export default Element;
