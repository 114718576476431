// Third party
import React from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../components/Layout";
import Section from "../components/Section";
import Container from "../components/Container";
import Hero from "../components/Hero";
import Breadcrumb from "../components/Breadcrumb";
import ContentList from "../components/ContentList";
import Seo from "../components/seo";

const breadcrumbs = [
  {
    id: 1,
    title: "Home",
    slug: "/",
  },
  {
    id: 2,
    title: "Blog",
    slug: "/blog/",
  },
];

const BlogPage = ({ data }) => {
  const {
    allContentfulPost: { edges: items },
  } = data;
  return (
    <div>
      <Layout>
        <Seo
          title="Blog: Hormoon Yoga"
          description="Blog artikelen over Hormoon Yoga"
          slug={"blog"}
        />
        <Hero small>
          <Container centered>
            <h1>Blog</h1>
            <p>Blog artikelen over Hormoon Yoga</p>
          </Container>
        </Hero>
        <Breadcrumb items={breadcrumbs} />
        <Section>
          <Container>
            <ContentList items={items} parent="blog" />
          </Container>
        </Section>
      </Layout>
    </div>
  );
};

export default BlogPage;

export const query = graphql`
  query ExcerptQuery {
    allContentfulPost(filter: { node_locale: { eq: "nl" } }, limit: 1000) {
      edges {
        node {
          id
          title
          slug
          createdAt
          author {
            title
            slug
            body {
              childMarkdownRemark {
                html
              }
            }
          }
          body {
            childMarkdownRemark {
              excerpt
            }
          }
        }
      }
    }
  }
`;
